import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from '@sentry/react';
import LoadingScreen from '../../Ui/LoadingScreen/LoadingScreen';

import classes from './UiMock.module.css';
import ToggleSwitch from '../../Ui/ToggleComponent/Toggle';
import NewMock from './NewMock/NewMock';

const UiMock = (props) => {
  const prevPropRef = useRef(null);
  const [htmlApiLoading, setHtmlApiLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [newarch, setNewarch] = useState(false);

  useEffect(() => {
    Sentry.close(1000).then(function () {
      // perform something after close
      console.info('disabling sentry tracing/logging...');
    });
    props.setCurrentRouteState('um', 'Ui Mock');
    if (!props.userfleetApiLoading) {
      $('.' + classes.UiMock).load('/html/angularContainer.html', () => {
        const url = 'http://localhost:5000/dist';
        axios
          .get(url, {
            headers: { Authorization: `Bearer ${props.settings.token}` }
          })
          .then((response) => {
            $('#angularContainer').load(window.makeTextFile(response.data));
            setHtmlApiLoading(false);
          })
          .catch((err) => {
            //console.error(err);
            setHtmlApiLoading(false);
            setShowAlert(true);
          });
      });
    }
    if (prevPropRef.userfleetApiLoading !== props.userfleetApiLoading) {
      setHtmlApiLoading(true);
      $('.' + classes.UiMock).load('/html/angularContainer.html', () => {
        const url = 'http://localhost:5000/dist';
        axios
          .get(url, {
            headers: { Authorization: `Bearer ${props.settings.token}` }
          })
          .then((response) => {
            $('#angularContainer').load(window.makeTextFile(response.data));
            setHtmlApiLoading(false);
          })
          .catch((err) => {
            //console.error(err);

            setHtmlApiLoading(false);
            setShowAlert(true);
          });
      });
    }
    if (newarch) {
      setHtmlApiLoading(false);
    }
  }, [newarch]);

  return (
    <React.Fragment>
      <br></br>
      {htmlApiLoading || props.userfleetApiLoading ? <LoadingScreen /> : null}
      <ToggleSwitch checked={newarch} updateValue={setNewarch}>
        <h2>New Mock</h2>
      </ToggleSwitch>
      <br></br>
      {newarch ? (
        <NewMock {...props}></NewMock>
      ) : showAlert ? (
        <React.Fragment>
          <div className={classes.Banner}>
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAFrSURBVEhLxZTPSsNAEMZXnymbQk+6CXooLVJREIuexJtHrzn6At58Cy9ePPgoKngStXSz1FLNOLPuSpNs/jUBP/hgkhm+X7KbLGsiYGwDIrZpLruVDPlhLPhbLPxpHPgn5nY3ksKbSMG/MBjIMvATJfxz026nbHinkKJw61aQqnDrtSCucDXchsXNtTbVq71GkKInV0cjsKI6268FKVsWNd4x8QjA2jVTCqlaczUSJh4BWLtmyE6IFP5B5YaGPRMPunbOGBMkDvipiScAf3ENZg3Jt7arlzd//ztW8A2e3ENpJ7HUdvUcfqWzSwPwYoCQRWYg52T6oe3qrZqWm5Zdh1vNQm9cBaHNLdtg8m+4NzGxaZVCdvuwvLuF5cM9/mhb+T66NNyqCKKO98w3BDC/OEv1yLXCrYog8+gSPq+i3GfaKNyqzp6Q1wq3qoK0CrcqgnQSbpWFdBpuhcEDGfBHBD3Pgt6+uf3fYuwHjmTot/MxIpMAAAAASUVORK5CYII="
              alt=""
            />
            <div className={classes.BannerText}>
              Local server not running. Please start the local development
              server for your service and press refresh.
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className={classes.UiMock}></div>
      )}
    </React.Fragment>
  );
};

export default UiMock;
