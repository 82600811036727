import axiosv2 from '../index';

function contractServiceMapper(contracts) {
  let contractServiceMapping = { monitors: [], advisors: [] };
  let contractsArray = [];
  if (contracts) {
    contracts.forEach((contract) => {
      if (contract.includes('.')) {
        const contractService = contract.split('.')[0];
        const contractType = contract.split('.')[1];
        contractsArray.push(contract);
        if (contractType === 'monitor')
          contractServiceMapping.monitors.push(contractService);
        if (contractType === 'premium' || contractType === 'l')
          contractServiceMapping.advisors.push(contractService);
      }
    });
  }
  return {
    contractServiceMapping,
    contractsArray
  };
}

function createPpidPnameMap(ppidToPnameMapping, pnameToPpidMapping, equipment) {
  if (
    equipment['plant-name'] !== null &&
    equipment['ppid'] !== null &&
    !ppidToPnameMapping.hasOwnProperty(equipment['ppid'])
  ) {
    ppidToPnameMapping[equipment['ppid']] = equipment['plant-name'];
    pnameToPpidMapping[equipment['plant-name']] = equipment['ppid'];
  }
  if (equipment['unit-name'] !== null && equipment['unit_id'] !== null) {
    ppidToPnameMapping[equipment['unit_id']] = equipment['unit-name'];
    pnameToPpidMapping[equipment['unit-name']] = equipment['unit_id'];
  }
}

function createPpidToAnameMap(ppidToAnameMapping, equipment) {
  if (
    equipment['account-name'] !== null &&
    equipment['ppid'] !== null &&
    !ppidToAnameMapping.hasOwnProperty(equipment['ppid'])
  ) {
    ppidToAnameMapping[equipment['ppid']] = equipment['account-name'];
  }
  if (equipment['account-name'] !== null && equipment['unit_id'] !== null) {
    ppidToAnameMapping[equipment['unit_id']] = equipment['account-name'];
  }
}

function createPpidToUnitsMap(ppidToUnitsMapping, equipment) {
  if (equipment['ppid'] !== null && ppidToUnitsMapping[equipment['ppid']]) {
    if (equipment['unit_id'] !== null) {
      let tempSet = ppidToUnitsMapping[equipment['ppid']];
      tempSet.add(equipment['unit_id']);
      ppidToUnitsMapping[equipment['ppid']] = tempSet;
    }
  } else {
    if (equipment['unit_id'] !== null) {
      let tempSet = new Set();
      tempSet.add(equipment['unit_id']);
      ppidToUnitsMapping[equipment['ppid']] = tempSet;
    }
  }
}

function createUnameToPnameMap(unameToPnameMapping, equipment) {
  if (equipment['unit-name'] !== null && equipment['plant-name'] !== null) {
    unameToPnameMapping[equipment['unit-name']] = equipment['plant-name'];
  }
}

function createEqIDPpidMap(
  ppidToequipmentIdMapping,
  equipmentIdToPpidMapping,
  equipment
) {
  if (equipment['ppid'] !== null && equipment['id'] !== null) {
    if (equipment['unit_id'] !== null) {
      ppidToequipmentIdMapping[equipment['unit_id']] = equipment['id'];
      equipmentIdToPpidMapping[equipment['id']] = equipment['unit_id'];
    } else {
      ppidToequipmentIdMapping[equipment['ppid']] = equipment['id'];
      equipmentIdToPpidMapping[equipment['id']] = equipment['ppid'];
    }
  }
}

function createAnametoPlantNameMap(accountNamePlantNameMapping, equipment) {
  if (
    equipment['account-name'] !== null &&
    accountNamePlantNameMapping[equipment['account-name']]
  ) {
    let tempSet = accountNamePlantNameMapping[equipment['account-name']];
    tempSet.add(equipment['plant-name']);
    accountNamePlantNameMapping[equipment['account-name']] = tempSet;
  } else {
    let tempSet = new Set();
    tempSet.add(equipment['plant-name']);
    accountNamePlantNameMapping[equipment['account-name']] = tempSet;
  }
}

function strBoleanHandler(data, key) {
  if (data[key] === 'true') {
    return true;
  }
  if (data[key] === 'false') {
    return false;
  }
  return null;
}

// CEP api resource of icma
const cepApiResource = 'cep';
let ppidToPnameMapping = {};
let pnameToPpidMapping = {};
let accountNamePlantNameMapping = {};
let ppidToAnameMapping = {};
let ppidToUnitsMapping = {};
let unameToPnameMapping = {};
let ppidToequipmentIdMapping = {};
let equipmentIdToPpidMapping = {};
let ppidToContractsMapping = {};
let username;
// Axios api for getting the userfleet info from snap
export const cep = {
  userfleetInfo: (ppid, email) => {
    return axiosv2
      .get(
        `${cepApiResource}/cep.snap/${ppid}?function=userfleet&email=${email}`
      )
      .then((response) => {
        username = response.data['name'];
        const equipments = response.data['Equipment'];

        const formattedEquipments = [];
        let parentID = [];
        equipments.forEach((equipment) => {
          const equipmentObj = {
            aname: equipment?.['account-name'] ?? '-',
            pname: equipment?.['plant-name'] ?? '-',
            uname: equipment?.['unit-name'] ?? '-',
            ppid: equipment?.['ppid'],
            unitId: equipment?.['unit_id']
          };
          formattedEquipments.push(equipmentObj);

          //for creating accountNamePlantNameMapping
          createAnametoPlantNameMap(accountNamePlantNameMapping, equipment);

          //for creating ppid to plant name mapping
          createPpidPnameMap(ppidToPnameMapping, pnameToPpidMapping, equipment);

          //for creating ppid to account name mapping
          createPpidToAnameMap(ppidToAnameMapping, equipment);

          //for creating ppid to unit mapping
          createPpidToUnitsMap(ppidToUnitsMapping, equipment);

          //for creating unit name to plant name mapping
          createUnameToPnameMap(unameToPnameMapping, equipment);

          //for creating ppid/unitid to equipmentId mapping
          createEqIDPpidMap(
            ppidToequipmentIdMapping,
            equipmentIdToPpidMapping,
            equipment
          );

          //for ppid/unitid to contracts mapping
          const { service_contracts } = equipment;
          if (equipment['ppid'] !== null && service_contracts !== null) {
            const contract = service_contracts.split(';');
            if (equipment['unit_id'] !== null) {
              ppidToContractsMapping[equipment['unit_id']] = contract;
            } else {
              ppidToContractsMapping[equipment['ppid']] = contract;
            }
          }
          if(equipment["unit_id"]===window.$globalSettings.ppid){
            parentID=equipment["ppid"];
          }
        });

        // get the details of flag from the snap user details
        const isRec = strBoleanHandler(response.data, 'is_rec');
        const isAdmin = strBoleanHandler(response.data, 'is_admin');
        const dsAdmin = strBoleanHandler(response.data, 'ds_admin');
        const isSiemens =
          response.data['email'].includes('@siemens.com') ||
          response.data['email'].includes('@siemens-energy.com')
            ? true
            : false;
        let ppid = window.$globalSettings.ppid;
        let language = window.$globalSettings.language;

        let pname = ppidToPnameMapping[ppid];

        let aname = ppidToAnameMapping[ppid];

        let equipmentId = ppidToequipmentIdMapping[ppid];

        let contracts = ppidToContractsMapping[ppid];

        const isTrPremium =
          contracts.indexOf('tr.premium') !== -1 ? true : false;

        let { contractServiceMapping } = contractServiceMapper(contracts);
        window.$globalSettings.equipmentId = equipmentId;
        window.$globalSettings.ppid = ppid;
        window.$globalSettings.pname = pname;

        window.$globalSettings.language = language;
        window.$globalSettings.username = username;

        // window.$globalSettings.changePpid = changePpid;

        let showPlantSelection = false;

        // mimic case
        let isMimic = false;
        let mimicedEmail = '';
        if (response.data.hasOwnProperty('mimiced')) {
          isMimic = true;
          mimicedEmail = response.data['mimiced'];
        }
        return {
          ppid: ppid,
          username: username,
          pname: pname,
          aname: aname,
          email: email,
          ppidToPnameMapping: ppidToPnameMapping,
          pnameToPpidMapping: pnameToPpidMapping,
          ppidToAnameMapping: ppidToAnameMapping,
          accountNamePlantNameMapping: accountNamePlantNameMapping,
          ppidToUnitsMapping: ppidToUnitsMapping,
          unameToPnameMapping: unameToPnameMapping,
          ppidToequipmentIdMapping: ppidToequipmentIdMapping,
          isTrPremium: isTrPremium,
          equipmentIdToPpidMapping: equipmentIdToPpidMapping,
          ppidToContractsMapping: ppidToContractsMapping,
          equipmentId: equipmentId,
          email: email,
          userfleetApiLoading: false,
          isSiemens: isSiemens,
          isRec: isRec,
          isAdmin: isAdmin,
          dsAdmin: dsAdmin,
          contracts: contractServiceMapping,
          language: language,
          isMimic: isMimic,
          mimicedEmail: mimicedEmail,
          showPlantSelection,
          equipments: formattedEquipments,
          isUserFleetDetailsLoaded: true,
          parentID: parentID
        };
      });
  },
  footer: (ppid, function_value) => {
    return axiosv2
      .get(`${cepApiResource}/cep.footer/${ppid}?function=${function_value}`)
      .then(({ data }) => {
        if (data.versions) {
          data.versions = data.versions.sort((a, b) => {
            let aNumber = a.toLowerCase().replace('v', '');
            let bNumber = b.toLowerCase().replace('v', '');
            return bNumber
              .replace(/\d+/g, (n) => +n + 100000)
              .localeCompare(aNumber.replace(/\d+/g, (n) => +n + 100000));
          });
        }
        return data;
      });
  },
  notify: (ppid) => {
    return axiosv2
      .get(`${cepApiResource}/cep.notify/${ppid}`)
      .then(({ data }) => {
        return {
          notifications: data
        };
      });
  },
  mapping: (ppid, option, id) => {
    let urlpath;
    if (option === 'fetchWithIBID') {
      urlpath = `${cepApiResource}/cep.mapping/${ppid}?function=fetchWithIBID&ibid=${id}`;
    }
    if (option === 'fetchWithSID') {
      urlpath = `${cepApiResource}/cep.mapping/${ppid}?function=fetchWithSID&sid=${id}`;
    }
    if (option === 'fetchWithPPID') {
      urlpath = `${cepApiResource}/cep.mapping/${ppid}?function=fetchWithPPID&ppid=${ppid}`;
    }
    return axiosv2.get(urlpath).then(({ data }) => {
      return data;
    });
  },
  userinfo: (ppid, email, version) => {
    let body = { email: email, version: version };
    return axiosv2
      .post(`${cepApiResource}/cep.user/${ppid}`, body, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(({ data }) => {
        return data === 'ICMA user version updated' ? true : false;
      });
  }
};
