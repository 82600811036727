import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { makeServer } from './mock/config';
import axios from 'axios';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { initializeCiv4 } from './utils/initial';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {
  PublicClientApplication,
  InteractionRequiredAuthError,
  EventType
} from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { icmaEnv, getPreviousDate, cleanUrl } from './utils/common';
// const params = require('@dig-mosaic-infra/dls-jpportal-params');
const params = require('dls-jpportal-params');
let settings = {};
//default language to english
let currentLanguage = 'en';
const history = createBrowserHistory();

// Array of Route Config Objects
// Make sure the order of the routes is correct.
// The longest url under the same parent should be placed first and in decreasing order.
const routes = [
  { path: '/hl/components' },
  { path: '/hl/services' },
  { path: '/tp/services' },
  { path: '/sa/services' },
  { path: '/aa/monitor' },
  { path: '/aa/advisor' },
  { path: '/sh/monitor' },
  { path: '/sh/advisor' },
  { path: '/hl/monitor' },
  { path: '/hl/advisor' },
  { path: '/sa/monitor' },
  { path: '/sa/advisor' },
  { path: '/sa/advisor_civ4' },
  { path: '/tp/monitor' },
  { path: '/tp/advisor' },
  { path: '/tr' },
  { path: '/if' },
  { path: '/ds' },
  { path: '/cp' },
  { path: '/ap' },
  { path: '/' },
  { path: '/error' }
];

// setry setup if not local
if (!window.location.hostname.includes('localhost')) {
  Sentry.init({
    dsn: 'https://0991e49e421141b2b13fe3b68f555016@crash.siemens-energy.com/25',
    environment: icmaEnv(params().server),
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
          history,
          routes,
          matchPath
        )
      }),
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['error', 'warn']
      })
    ],
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      let user = {
        email: event?.tags?.icma_email,
        name: event?.tags?.icma_username
      };
      if (event?.message?.includes('icma-user-feedback')) {
        console.log('showing Dialog');
        Sentry.showReportDialog({
          eventId: event.event_id,
          user: user,
          title: 'Report an issue or send feedback',
          subtitle:
            'Notify the team about any problem while using ICMA or submit your feedback (internal use only)',
          subtitle2: '',
          labelSubmit: 'Send'
        });
      }
      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const identityProvider = urlParams.get('sso');
let backtoCEP = urlParams.get('backtocep');

// store in local stage , because during AZ SSO url params will be reset
if (backtoCEP !== null) {
  localStorage.setItem('icmaredirect', backtoCEP);
}

let localSso = localStorage.getItem('icma_sso');
localStorage.setItem('icma_sso', 'az');
let redirectURL = window.location.href.split('?')[0];
msalConfig.auth.redirectUri = redirectURL;
let url = new URL(window.location.href);

// priority to language in url params
if (
  url.searchParams.get('language') !== null &&
  (url.searchParams.get('language') == 'en' ||
    url.searchParams.get('language') == 'de')
) {
  currentLanguage = url.searchParams.get('language');
  console.log('language set by url ...', currentLanguage);
} else {
  // else language store in local storage because user can reload without url params
  if (localStorage.getItem('icmalanguage') !== null) {
    currentLanguage = localStorage.getItem('icmalanguage');
    console.log('language set by local...', currentLanguage);
  } else {
    // default to english
    console.log('language set by default...', currentLanguage);
  }
}

localStorage.setItem('icmalanguage', currentLanguage);

if (url.searchParams.get('ppid') !== null) {
  localStorage.setItem('icmaid', url.searchParams.get('ppid'));
}
// Azure Ad login Code Start
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}
msalInstance.addEventCallback(
  (event) => {
    // set active account after redirect
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  },
  (error) => {
    console.log('addEventCallback error', error);
  }
);

msalInstance.handleRedirectPromise().then(async (redirectResponse) => {
  // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
  const account = msalInstance.getAllAccounts()[0];
  // Acquire token silent success
  const accessTokenRequest = {
    // scopes: ['user.read'],
    account: account,
    scopes: [`b20c2be5-3542-4edb-8d98-ad09835e989e/.default`]
    // api: ['https://native-app.icma-dev.siemens-energy.cloud/default']
  };
  if (redirectResponse !== null) {
    msalInstance
      .acquireTokenSilent(accessTokenRequest)
      .then(function (accessTokenResponse) {
        let accessToken = accessTokenResponse.accessToken;
        // Call your API with token
        callAppWithToken(accessToken);
      });
  } else {
    if (!account) {
      // redirect anonymous user to login page
      msalInstance.loginRedirect();
    } else {
      msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .then(function (accessTokenResponse) {
          // Acquire token silent success
          // Call API with token
          let accessToken = accessTokenResponse.accessToken;
          // Call your API with token
          callAppWithToken(accessToken);
        })
        .catch(function (error) {
          //Acquire token silent failure, and send an interactive request
          console.log('acquireTokenSilent error', error);
          if (error instanceof InteractionRequiredAuthError) {
            msalInstance.acquireTokenRedirect(accessTokenRequest);
          }
        });
    }
  }
});
// Azure Ad login Code End

function callAppWithToken(token) {
  localStorage.setItem('dls_token_sp10', token);
  settings = {
    server: params().server,
    ppid: params().ppid,
    // ppid: '41096',
    sid: params().sid,
    ibid: params().ibid,
    language: currentLanguage,
    startDate: getPreviousDate(2),
    endDate: getPreviousDate(1),
    silentPpidChangeApi: '/index.php?service=change_unit_withoutPageLoad',
    token: token,
    customerPortalApiUrl: '/index.php?service=userfleetinfo',
    mockApis: false,
    customdomainpath: 'cep',
    redirectToCepUrl: ''
  };

  // update the cep redirect url using localstorage
  backtoCEP = localStorage.getItem('icmaredirect');
  if (backtoCEP !== null) {
    settings.redirectToCepUrl = backtoCEP;
  }

  // getting ppid from local storage if url parameter doesnot contain ppid and language
  if (
    localStorage.getItem('icmaid') !== null &&
    localStorage.getItem('icmalanguage') !== null &&
    (settings.ppid === 'T0002' || settings.ppid === 'BLANK')
  ) {
    window.$globalSettings.ppid = localStorage.getItem('icmaid');
    settings.ppid = localStorage.getItem('icmaid');
    window.$globalSettings.language = localStorage.getItem('icmalanguage');
    settings.language = localStorage.getItem('icmalanguage');
  }

  // Function to initialize CIv4 Page Enable Flag
  initializeCiv4();

  // update the globalsettings loginMethod for service pages
  if (localSso === 'az' || identityProvider === 'az') {
    window.$globalSettings['loginMethod'] = 'az';
  }

  // overrighting global setting start and end date
  window.$globalSettings.startDate = settings.startDate;
  window.$globalSettings.endDate = settings.endDate;

  if (settings.mockApis) {
    makeServer({ environment: 'development' });
  }

  // removing query parameters from the url
  window.history.replaceState({}, document.title, '/' + cleanUrl());

  // Add a request interceptor
  const axiosReqAddAuth = (config) => {
    config.headers['Authorization'] = 'Bearer ' + token;
    config.baseURL = params().server;
    return config;
  };
  axios.interceptors.request.use(axiosReqAddAuth);
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={configureStore}>
        <BrowserRouter>
          <App settings={settings} />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
export default axios;
