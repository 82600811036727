export const noParams = window.location.href.split('?')[0];
export const currentRoute = noParams.substring(noParams.lastIndexOf('/'));

export const pushToError = (props, pname, error, url) => {
  props.history.push(
    `/error?plantName=${pname}&timeStamp=${new Date().toUTCString()}&route=${currentRoute}&error=${error}&url=${url.toString()}`
  );
};

export const getItemFromSessionStorage = (name) => {
    return JSON.parse(sessionStorage.getItem(name));
}

export const getCurrentRoute = () => {
  return window.location.pathname.slice(1, window.location.pathname.length);
}

export const icmaEnv = (serverUrl) => {
  if (serverUrl.includes('dev')) return 'DEV';
  if (serverUrl.includes('qa')) return 'QA';
  return 'PROD';
}

export const cleanUrl = () => {
  let onlyRoute = noParams.split('/').slice(3).join('/');
  // remove accesstoken from the url
  if (window.location.href.includes('#access_token')) {
    let urlParams = window.location.href.split('#')[0];
    onlyRoute = urlParams.split('/').slice(3).join('/');
  }
  return onlyRoute;
}

export const getPreviousDate = (numDays) => {
  let today = new Date();
  let previousDate = new Date(today);
  previousDate.setDate(today.getDate() - numDays);
  let previousDateFormatted = previousDate.toISOString().slice(0, 10);
  return previousDateFormatted;
}