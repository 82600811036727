import React, { Component } from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import SideDrawer from '../../components/SideDrawer/SideDrawer';
import TermsAndConditions from '../../components/StaticComponents/TermsAndConditions/TermsAndConditions';
import VersionModel from '../../components/StaticComponents/VersionModel/VersionModel.js';
import PlantSelector from '../../components/PlantSelector/PlantSelector';
import ErrorRoute from '../../components/StaticComponents/ErrorRoute/ErrorRoute';
// import LandingPage from '../../components/ServiceComponents/LandingPage/LandingPage';
import LandingPage from '../../components/ServiceComponents/LandingPage/LandingPageComponent';
import IAndCArchiveMonitor from '../../components/ServiceComponents/IAndCArchive/IAndCArchiveMonitor/IAndCArchiveMonitor';
import IAndCArchiveAdvisor from '../../components/ServiceComponents/IAndCArchive/IAndCArchiveAdvisor/IAndCArchiveAdvisor';
import IAndCArchiveServices from '../../components/ServiceComponents/IAndCArchive/IAndCArchiveServices/IAndCArchiveServices';
import IAndCConditionMonitor from '../../components/ServiceComponents/IAndCCondition/IAndCConditionMonitor/IAndCConditionMonitor';
import IAndCConditionAdvisor from '../../components/ServiceComponents/IAndCCondition/IAndCConditionAdvisor/IAndCConditionAdvisor';
import IAndCConditionServices from '../../components/ServiceComponents/IAndCCondition/IAndCConditionServices/IAndCConditionServices';
import IAndCLifecycleMonitor from '../../components/ServiceComponents/IAndCLifecycle/IAndCLifecycleMonitor/IAndCLifecycleMonitor';
import IAndCLifecycleAdvisor from '../../components/ServiceComponents/IAndCLifecycle/IAndCLifecycleAdvisor/IAndCLifecycleAdvisor';
import IAndCLifecycleServices from '../../components/ServiceComponents/IAndCLifecycle/IAndCLifecycleServices/IAndCLifecycleServices';
import IAndCLifecycleComponents from '../../components/ServiceComponents/IAndCLifecycle/IAndCLifecycleComponents/IAndCLifecycleComponents';
import IAndCSecurityMonitor from '../../components/ServiceComponents/IAndCSecurity/IAndCSecurityMonitor/IAndCSecurityMonitor';
import IAndCSecurityAdvisor from '../../components/ServiceComponents/IAndCSecurity/IAndCSecurityAdvisor/IAndCSecurityAdvisor';
import IAndCSecurityAdvisorCIv4 from '../../components/ServiceComponents/IAndCSecurity/IAndCSecurityAdvisorCIv4/IAndCSecurityAdvisorCIv4';
import IAndCSecurityServices from '../../components/ServiceComponents/IAndCSecurity/IAndCSecurityServices/IAndCSecurityServices';
import ProcessOptimizationMonitor from '../../components/ServiceComponents/ProcessOptimization/ProcessOptimizationMonitor/ProcessOptimizationMonitor';
import ProcessOptimizationAdvisor from '../../components/ServiceComponents/ProcessOptimization/ProcessOptimizationAdvisor/ProcessOptimizationAdvisor';
import ProcessOptimizationServices from '../../components/ServiceComponents/ProcessOptimization/ProcessOptimizationServices/ProcessOptimizationServices';
import T3000Reports from '../../components/ServiceComponents/T3000Reports/T3000Reports';
// import T3000Reports from '../../components/ServiceComponents/T3000Reports/T3000ReportsOld';
import InternalFunctions from '../../components/ServiceComponents/InternalFunctions/InternalFunctions';
import DataShopping from '../../components/ServiceComponents/DataShopping/DataShopping';
// import AdminPage from '../../components/ServiceComponents/AdminPage/AdminPage';
import AdminPage from '../../components/ServiceComponents/AdminPage/AdminPageComponent';
// import ConfigPage from '../../components/ServiceComponents/ConfigPage/ConfigPage';
import ConfigPage from '../../components/ServiceComponents/ConfigPage/ConfigurationPageComponent';
import UiMock from '../../components/ServiceComponents/UiMock/UiMock';
// import LoadingScreen from '../../components/Ui/LoadingScreen/LoadingScreen';
import classes from './Layout.module.css';
import adminGroups from '../../config/ap_groups.json';
import { connect } from 'react-redux';
import { updateFleet } from '../../store/userFleetSlice';
import { cep } from '../../services/cep';
import { pi } from '../../services/pi';
import { pushToError } from '../../utils/common';
import { maintenance } from '../../services/maintenance';

class Layout extends Component {
  state = {
    showSideDrawer: false,
    currentService: '',
    currentServiceName: 'Available Services',
    showPlantSelection: false,
    showTermsAndConditions: false,
    showVersionModel: false,
    username: null,
    ppid: this.props.settings.ppid,
    language: 'en',
    pname: null,
    aname: null,
    accountNamePlantNameMapping: {},
    ppidToPnameMapping: {},
    ppidToAnameMapping: {},
    ppidToUnitsMapping: {},
    unameToPnameMapping: {},
    userfleetApiLoading: true,
    shaUnderMaintenanceApiLoading: true,
    hlUnderMaintenanceApiLoading: true,
    saUnderMaintenanceApiLoading: true,
    shaUnderMaintenance: {},
    hlUnderMaintenance: {},
    saUnderMaintenance: {},
    equipmentId: null,
    email: null,
    isSiemens: false,
    isRec: false,
    isAdmin: false,
    dsAdmin: false,
    isTrPremium: false,
    isUserFleetDetailsLoaded: false,
    ppidToContractsMapping: {},
    contracts: { monitors: [], advisors: [] },
    isMimic: false,
    mimicedEmail: 'Default mimic email',
    test: 'Testing something good',
    equipments: [],
    notifications: [],
    latest_version: '',
    version_info: {},
    configServices: {},
    lpMapping: {},
    parentID:""
  };
  handleuserFleetSaveValue = (value) => {
    this.props.dispatch(updateFleet(value));

  };

  shMaintenance(ppid, pname) {
    maintenance
      .shMaintenance(ppid, pname)
      .then((response) => {
        this.setState({
          shaUnderMaintenanceApiLoading: false,
          shaUnderMaintenance: {
            isUnderMnt: response.isUnderMnt,
            reason: response.reason
          }
        });
      })
      .catch((error) => {
        this.setState({
          shaUnderMaintenanceApiLoading: false
        });
        console.log(error);
      });
  }

  saMaintenance(ppid, pname) {
    maintenance
      .saMaintenance(ppid, pname)
      .then((response) => {
        this.setState({
          saUnderMaintenanceApiLoading: false,
          saUnderMaintenance: {
            isUnderMnt: response.isUnderMnt,
            reason: response.reason
          }
        });
      })
      .catch((error) => {
        this.setState({
          saUnderMaintenanceApiLoading: false
        });
        console.log(error);
      });
  }

  hlMaintenance(ppid, pname) {
    maintenance
      .hlMaintenance(ppid, pname)
      .then((response) => {
        this.setState({
          hlUnderMaintenanceApiLoading: false,
          hlUnderMaintenance: {
            isUnderMnt: response.isUnderMnt,
            reason: response.reason
          }
        });
      })
      .catch((error) => {
        this.setState({
          hlUnderMaintenanceApiLoading: false
        });
        console.log(error);
      });
  }
  getGroups() {
    const groups = [];
    groups.push(adminGroups);
    return groups;
  }
  contractServiceMapper(contracts) {
    let contractServiceMapping = { monitors: [], advisors: [] };
    let contractsArray = [];
    if (contracts) {
      contracts.forEach((contract) => {
        if (contract.includes('.')) {
          const contractService = contract.split('.')[0];
          const contractType = contract.split('.')[1];
          contractsArray.push(contract);
          if (contractType === 'monitor')
            contractServiceMapping.monitors.push(contractService);
          if (contractType === 'premium' || contractType === 'l')
            contractServiceMapping.advisors.push(contractService);
        }
      });
    }
    return {
      contractServiceMapping,
      contractsArray
    };
  }

  getNotificationDetails() {
    cep
      .notify(this.state.ppid)
      .then((response) => {
        this.setState({ ...response });
      })
      .catch((err) => {
        console.error('error fetching notification', err);
        // redirect to error page
        pushToError(this.props, this.state.pname, err, '/notify');
      });
  }

  getUserFleetDetails(email) {
    cep
      .userfleetInfo(this.state.ppid, email)
      .then((response) => {
        this.handleuserFleetSaveValue(response);
        // Sentry.setTag('icma_username', response.username);
        // Sentry.setUser({ username: response.username });
        // direct url to native app case
        if (response.ppidToPnameMapping[this.state.ppid] === undefined) {
          console.warn('Currently, plant is not selected...');
          // ppid and language in storage ?
          if (localStorage.getItem('icmaid') == null) {
            console.warn('kindly select a power plant...');
            response.showPlantSelection = true;
            response.aname = Object.keys(
              response.accountNamePlantNameMapping
            )[0];
          }
        }
        // store the value in local storage
        else {
          localStorage.setItem('icmaid', this.state.ppid);
          localStorage.setItem('icmalanguage', this.props.settings.language);
        }
        //Api calls for fetch under maintenance status
        this.shMaintenance(response.ppid, response.pname);
        this.hlMaintenance(response.ppid, response.pname);
        this.saMaintenance(response.ppid, response.pname);
        this.setState({ ...response });
      })
      .catch((err) => {
        console.error('failed to fetch user fleet information ->', err);
        this.setState({
          userfleetApiLoading: false,
          shaUnderMaintenanceApiLoading: false,
          hlUnderMaintenanceApiLoading: false,
          saUnderMaintenanceApiLoading: false,
          isUserFleetDetailsLoaded: true
        });
        pushToError(this.props, this.state.pname, err, '/userfleet');
      });
  }

  getIcmaMapping(email, ppid = null, sid = null, doFetchUserFleet) {
    let option;
    if (ppid === 'BLANK' && sid !== null) {
      option = 'fetchWithSID';
    } else {
      option = 'fetchWithPPID';
    }
    cep
      .mapping(ppid, option, this.props.settings.sid)
      .then((response) => {
        // in case mapping for the ppid doesn't exist
        if (response === 'Error') {
          console.error('mapper response error for..', ppid);
          // since mapping is unavailable using the params ppid
          window.$globalSettings.ppid = ppid;
          window.$globalSettings.sid = 'NO ENTRY';
          window.$globalSettings.ibid = 'NO ENTRY';
          if (doFetchUserFleet) {
            this.getUserFleetDetails(email);
          }
        } else {
          // mapping found using the data from mapping api
          if (response['PPID'] !== null) {
            window.$globalSettings.ppid = response['PPID'];
            window.$globalSettings.sid = response['SalesforceID'];
            window.$globalSettings.ibid = response['IBID'];
            //api to get user details for plant acces and portal access
            if (doFetchUserFleet) {
              this.getUserFleetDetails(email);
            }
          }
        }
      })
      .catch((err) => {
        console.log('kindly select a plant...geticmamapping failed..', err);
        this.openPlantSelection();
      });
  }

  // get footer details
  getVersionInformation(email) {
    cep
      .footer(this.state.ppid, 'latest')
      .then((data) => {
        this.setState(
          {
            version_info: data
          },
          () => {
            this.getUserInfo(email, data);
          }
        );
      })
      .catch((err) => console.log(err));
  }

  // check which version user was using
  getUserInfo(email, value) {
    cep
      .userinfo(this.state.ppid, email, value['latest_version'])
      .then((response) => {
        // if user was using oler version then open the popup
        response && this.openVersionModal();
      });
  }

  componentDidMount() {
    let email = this.getEmailFromToken(this.props.settings.token);
    // Sentry.setTag('icma_email', email);
    Sentry.setUser({ email });
    pi.mapping(this.state.ppid, email).then((response) => {
      this.setState({ lpMapping: response });
    });
    //Api call for user details for plant acces and portal access
    if (this.state.ppid === 'BLANK' && this.props.settings.sid !== 'BLANKSID') {
      this.getIcmaMapping(
        email,
        this.state.ppid,
        this.props.settings.sid,
        true
      );
    } else {
      // for ppid in the url
      //api toget user details for plant acces and portal access
      this.getUserFleetDetails(email);
      this.getIcmaMapping(
        email,
        this.state.ppid,
        this.props.settings.sid,
        false
      );
    }
    // api to get current active notification
    this.getNotificationDetails();
    this.getVersionInformation(email);
    pi.serviceStructures(
      this.state.ppid,
      process.env.SERVICE_KEY || 'master'
    ).then((response) => {
      this.setState({ configServices: response });
    });
  }

  closeSideDrawerHandler = () => {
    this.setState({
      showSideDrawer: false
    });
  };

  toggleSideDrawerHandler = () => {
    this.setState((prevState) => {
      return { showSideDrawer: !prevState.showSideDrawer };
    });
  };

  activeServiceHandler = (service) => {
    if (this.state.currentService === service) {
      let currentPathName = this.props.history.location.pathname;
      //case for nested routes like /aa/monitor
      let curPathArray = currentPathName.split('/');
      if (curPathArray.length > 2) {
        currentPathName = `/${curPathArray[curPathArray.length - 2]}/${
          curPathArray[curPathArray.length - 1]
        }`;
      }
      this.props.history.replace({
        pathname: `/temp`
        // search: `?ppid=${this.state.ppid}&language=${this.state.language}`,
      });
      setTimeout(() => {
        this.props.history.replace({
          pathname: `${currentPathName}`
        });
      }, 0);

      this.closeSideDrawerHandler();
      return;
    }
    this.setState(
      {
        currentService: service
      },
      () => {
        this.props.history.push({
          pathname: '/' + this.state.currentService
        });

        this.closeSideDrawerHandler();
      }
    );
  };
  setCurrentRouteState = (service, serviceName) => {
    this.setState({
      currentService: service,
      currentServiceName: serviceName
    });
  };
  openPlantSelection = () => {
    this.setState({
      showPlantSelection: true,
      showSideDrawer: false
    });
  };
  hidePlantSelection = () => {
    this.setState({
      showPlantSelection: false
    });
  };
  openTermsAndConditions = () => {
    this.setState({
      showTermsAndConditions: true,
      showSideDrawer: false
    });
  };
  openVersionModal = () => {
    this.setState({
      showVersionModel: true,
      showSideDrawer: false
    });
  };
  hideTermsAndConditions = () => {
    this.setState({
      showTermsAndConditions: false
    });
  };
  hideVersionModel = () => {
    this.setState({
      showVersionModel: false
    });
  };
  changePpid = (ppid) => {
    this.setState({
      shaUnderMaintenanceApiLoading: true,
      saUnderMaintenanceApiLoading: true,
      hlUnderMaintenanceApiLoading: true,
      shaUnderMaintenance: {},
      hlUnderMaintenance: {},
      saUnderMaintenance: {},
      isTrPremium: false
    });
    let passedPpid;
    if (this.state.ppidToUnitsMapping[ppid]) {
      let unitArray = [...this.state.ppidToUnitsMapping[ppid]];
      //to find the smallest string
      let smallestppid = unitArray[0];
      unitArray.forEach((val) => {
        if (val < smallestppid) {
          smallestppid = val;
        }
      });
      passedPpid = smallestppid;
    } else {
      passedPpid = ppid;
    }

    let pname = this.state.ppidToPnameMapping[passedPpid];
    let aname = this.state.ppidToAnameMapping[passedPpid];
    let equipmentId = this.state.ppidToequipmentIdMapping[passedPpid];

    console.info('new plant is selected...');
    localStorage.setItem('icmaid', passedPpid);
    localStorage.setItem('icmalanguage', this.props.settings.language);
    //Api calls for fetch under maintenance status
    this.shMaintenance(passedPpid, pname);

    this.hlMaintenance(passedPpid, pname);

    this.saMaintenance(passedPpid, pname);

    let contracts = this.state.ppidToContractsMapping[passedPpid];
    let { contractServiceMapping } = this.contractServiceMapper(contracts);
    let isTrPremium;
    isTrPremium = contractServiceMapping.advisors.includes('tr') ? true : false;
    window.$globalSettings.ppid = passedPpid;
    window.$globalSettings.equipmentId = equipmentId;
    window.$globalSettings.pname = pname;
    this.getIcmaMapping(
      this.state.email,
      passedPpid,
      this.props.equipmentId,
      false
    );
    this.setState(
      {
        ppid: passedPpid,
        pname: pname,
        aname: aname,
        showPlantSelection: false,
        equipmentId: equipmentId,
        contracts: contractServiceMapping,
        isTrPremium: isTrPremium
      },
      () => {
        //workaround for refreshing current route
        //this.props.history.push("/temp");
        this.props.history.push({
          pathname: `/${this.state.currentService}`
          // search: `?ppid=${this.state.ppid}&language=${this.state.language}`,
        });
        this.props.history.push({
          pathname: `/temp`
          // search: `?ppid=${this.state.ppid}&language=${this.state.language}`,
        });
        this.props.history.goBack();
      }
    );
  };
  refreshMock = () => {
    this.props.history.push({
      pathname: '/temp',
      search: `?ppid=${this.state.ppid}&language=${this.state.language}`
    });
    this.props.history.goBack();
  };
  getEmailFromToken = (token) => {
    let email = '';
    if (token) {
      let base64Url = token.split('.')[1];
      if (base64Url) {
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        let parsedSecurityToken = JSON.parse(window.atob(base64));
        if (
          parsedSecurityToken &&
          'http://cep.com/email' in parsedSecurityToken
        ) {
          email = parsedSecurityToken['http://cep.com/email'];
        }
        if (parsedSecurityToken && 'email' in parsedSecurityToken) {
          email = parsedSecurityToken['email'];
        }
      }
    }
    return email;
  };

  render() {
    return (
      <div className={classes.Layout}>
        <Navbar
          pname={this.state.pname}
          aname={this.state.aname}
          toggleSideDrawer={this.toggleSideDrawerHandler}
          currentService={this.state.currentService}
          activeService={(service) => this.activeServiceHandler(service)}
          currentServiceName={this.state.currentServiceName}
          openTermsAndConditions={this.openTermsAndConditions}
          refreshMock={this.refreshMock}
          isAdmin={this.state.isAdmin}
          isSiemens={this.state.isSiemens}
          contracts={this.state.contracts}
          openPlantSelection={this.openPlantSelection}
          ppidToUnitsMapping={this.state.ppidToUnitsMapping}
          ppid={this.state.ppid}
          parentID={this.state.parentID}
          ppidToPnameMapping={this.state.ppidToPnameMapping}
          changePpid={this.changePpid}
          notifications={this.state.notifications}
        />

        <SideDrawer
          open={this.state.showSideDrawer}
          show={this.state.showSideDrawer}
          close={this.closeSideDrawerHandler}
          activeService={(service) => this.activeServiceHandler(service)}
          currentService={this.state.currentService}
          openPlantSelection={this.openPlantSelection}
          username={this.state.username}
          unameToPnameMapping={this.state.unameToPnameMapping}
          pname={this.state.pname}
          isSiemens={this.state.isSiemens}
          isAdmin={this.state.isAdmin}
          ppid={this.state.ppid}
          contracts={this.state.contracts}
          redirectToCepUrl={this.props.settings.redirectToCepUrl}
          shaUnderMaintenance={this.state.shaUnderMaintenance}
          hlUnderMaintenance={this.state.hlUnderMaintenance}
          saUnderMaintenance={this.state.saUnderMaintenance}
          isMimic={this.state.isMimic}
          mimicedEmail={this.state.mimicedEmail}
        />

        {this.state.showPlantSelection ? (
          <PlantSelector
            pname={this.state.pname}
            aname={this.state.aname}
            equipments={this.state.equipments}
            unameToPnameMapping={this.state.unameToPnameMapping}
            ppidToPnameMapping={this.state.ppidToPnameMapping}
            accountNamePlantNameMapping={this.state.accountNamePlantNameMapping}
            close={this.hidePlantSelection}
            changePpid={(ppid) => this.changePpid(ppid)}
            ppid={this.state.ppid}
          />
        ) : null}
        {this.state.showTermsAndConditions ? (
          <TermsAndConditions close={this.hideTermsAndConditions} />
        ) : null}
        {this.state.showVersionModel ? (
          <VersionModel
            settings={this.props.settings}
            close={this.hideVersionModel}
            version_info={this.state.version_info}
          />
        ) : null}
        <div className={classes.Container}>
          {/* {this.state.userfleetApiLoading ||
          this.state.shaUnderMaintenanceApiLoading ||
          this.state.hlUnderMaintenanceApiLoading ||
          this.state.saUnderMaintenanceApiLoading ? (
            <LoadingScreen whatup={'native'} />
          ) : null} */}
          <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <LandingPage
                  {...this.props}
                  ppid={this.state.ppid}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  shaUnderMaintenance={this.state.shaUnderMaintenance}
                  hlUnderMaintenance={this.state.hlUnderMaintenance}
                  saUnderMaintenance={this.state.saUnderMaintenance}
                  lpMapping={this.state.lpMapping}
                  getEmailFromToken={this.getEmailFromToken}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />

            <Route
              path="/aa/monitor"
              render={() => (
                <IAndCArchiveMonitor
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />
            <Route
              path="/aa/advisor"
              render={() => (
                <IAndCArchiveAdvisor
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />
            <Route
              path="/aa/services"
              render={() => (
                <IAndCArchiveServices
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />

            <Route
              path="/sh/monitor"
              render={() => (
                <IAndCConditionMonitor
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />
            <Route
              path="/sh/advisor"
              render={() => (
                <IAndCConditionAdvisor
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />
            <Route
              path="/sh/services"
              render={() => (
                <IAndCConditionServices
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />

            <Route
              path="/hl/monitor"
              render={() => (
                <IAndCLifecycleMonitor
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />
            <Route
              path="/hl/advisor"
              render={() => (
                <IAndCLifecycleAdvisor
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />
            <Route
              path="/hl/services"
              render={() => (
                <IAndCLifecycleServices
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />
            <Route
              path="/hl/components"
              render={() => (
                <IAndCLifecycleComponents
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />

            <Route
              path="/sa/monitor"
              render={() => (
                <IAndCSecurityMonitor
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />
            <Route
              path="/sa/advisor"
              render={() => (
                <IAndCSecurityAdvisor
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />
            <Route
              path="/sa/advisor_civ4"
              render={() => (
                <IAndCSecurityAdvisorCIv4
                  {...this.props}
                  isSiemens={this.state.isSiemens}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />
            <Route
              path="/sa/services"
              render={() => (
                <IAndCSecurityServices
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />

            <Route
              path="/tp/monitor"
              render={() => (
                <ProcessOptimizationMonitor
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />
            <Route
              path="/tp/advisor"
              render={() => (
                <ProcessOptimizationAdvisor
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />
            <Route
              path="/tp/services"
              render={() => (
                <ProcessOptimizationServices
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                />
              )}
            />
            {(this.state.isTrPremium ||
              this.state.isSiemens ||
              this.state.isAdmin) && (
              <Route
                path="/tr"
                render={() => (
                  <T3000Reports
                    {...this.props}
                    userfleetApiLoading={this.state.userfleetApiLoading}
                    email={this.state.email}
                    lpMapping={this.state.lpMapping}
                    ppid={this.state.ppid}
                    setCurrentRouteState={(service, serviceName) =>
                      this.setCurrentRouteState(service, serviceName)
                    }
                  />
                )}
              />
            )}
            {(this.state.isSiemens || this.state.isAdmin) && (
              <Route
                path="/if"
                render={() => (
                  <InternalFunctions
                    {...this.props}
                    userfleetApiLoading={this.state.userfleetApiLoading}
                    setCurrentRouteState={(service, serviceName) =>
                      this.setCurrentRouteState(service, serviceName)
                    }
                  />
                )}
              />
            )}
            {(this.state.isSiemens || this.state.isAdmin) && (
              <Route
                path="/ds"
                render={() => (
                  <DataShopping
                    {...this.props}
                    userfleetApiLoading={this.state.userfleetApiLoading}
                    setCurrentRouteState={(service, serviceName) =>
                      this.setCurrentRouteState(service, serviceName)
                    }
                  />
                )}
              />
            )}
            {(this.state.isSiemens || this.state.isAdmin) && (
              <Route
                path="/ap"
                render={() => (
                  <AdminPage
                    {...this.props}
                    groups={this.getGroups()}
                    userfleetApiLoading={this.state.userfleetApiLoading}
                    setCurrentRouteState={(service, serviceName) =>
                      this.setCurrentRouteState(service, serviceName)
                    }
                  />
                )}
              />
            )}
            <Route
              path="/cp"
              render={() => (
                <ConfigPage
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                  ppidToPnameMapping={this.state.ppidToPnameMapping}
                  configServices={this.state.configServices}
                  lpMapping={this.state.lpMapping}
                  ppid={this.state.ppid}
                />
              )}
            />
            <Route
              path="/um"
              render={() => (
                <UiMock
                  {...this.props}
                  userfleetApiLoading={this.state.userfleetApiLoading}
                  setCurrentRouteState={(service, serviceName) =>
                    this.setCurrentRouteState(service, serviceName)
                  }
                  ppid={this.state.ppid}
                  shaUnderMaintenance={this.state.shaUnderMaintenance}
                  hlUnderMaintenance={this.state.hlUnderMaintenance}
                  saUnderMaintenance={this.state.saUnderMaintenance}
                  lpMapping={this.state.lpMapping}
                />
              )}
            />
            {/* component={ErrorRoute}  */}
            <Route path="/error" component={ErrorRoute} />
            {this.state.isUserFleetDetailsLoaded && (
              <Route
                render={() => (
                  <ErrorRoute
                    error={
                      "You dont have necessary permissions to view this page or the page doesn't exist"
                    }
                    route={'/'}
                    url={'/'}
                    plantName={this.state.pnopenTermsAndConditionsame}
                    timeStamp={new Date().toUTCString()}
                  />
                )}
              />
            )}
          </Switch>
        </div>
        <Footer
          version_info={this.state.version_info}
          settings={this.props.settings}
          openTermsAndConditions={this.openTermsAndConditions}
          openVersionModal={this.openVersionModal}
          className="testingFooter"
        ></Footer>
      </div>
    );
  }
}
const ConnectedLayoutComponent = connect()(Layout);
export default withRouter(ConnectedLayoutComponent);
